.legal {
  .legal__title {
    background-color: #073618;
    color: #fff;
    padding: 20px 0 50px;
    .legal__title__content {
      width: 70%;
      margin: auto;
      h2 {
        font-family: Montserrat-Bold;
        font-size: 45px;
      }
      p {
        font-family: Montserrat-Regular;
        font-size: 20px;
        width: 70%;
      }
    }
  }
  .legal__content {
    width: 70%;
    margin: 50px auto;
    .legal__content__title {
      //   .MuiTab-root.Mui-selected {
      //     color: #073618;
      //   }
      //   .MuiTabs-indicator {
      //     background-color: #00f96b;
      //   }

      button {
        font-family: Montserrat-Bold;
        font-size: 18px;
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .legal {
    .legal__title {
      padding: 20px;
      .legal__title__content {
        width: auto;
        p {
          width: auto;
        }
      }
    }
    .legal__content {
      width: auto;
      .legal__content__title {
        // .MuiTabs-flexContainer {
        //   // flex-wrap: wrap;
        //   justify-content: center;
        // }
        button {
          font-family: Montserrat-Bold;
          font-size: 13px;
          padding: 5px;
        }
      }
    }
  }
}
