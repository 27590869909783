.jobGroup {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 20px;
  .jobGroup__title {
    color: #36ae37;
    font-family: Montserrat-Regular;
    font-size: 35px;
    margin: 20px 0;
  }
  .jobGroup__jobsContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .jobGroup__job {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 30px 25px 5px 30px;
      width: 270px;
      height: 170px;
      box-shadow: 0px 0px 10px #161e5227;
      margin: 0 20px 20px 0;
      background-color: #ffffff;
      cursor: pointer;
      h3 {
        font-family: AvenirNext;
        font-size: 20px;
        margin: 20px 0 0;
        color: #22212b;
      }
      p {
        color: #6f6f6f;
        font-family: Montserrat-Regular;
        font-size: 15px;
        margin: 10px 0;
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .jobGroup {
    justify-content: center;
    width: 100%;
    padding: 20px 0;
    .jobGroup__title {
      text-align: center;
    }
    .jobGroup__jobsContainer {
      justify-content: center;
      .jobGroup__job {
        padding: 30px 15px 5px 15px;
        width: 240px;
      }
    }
  }
}
