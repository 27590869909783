.footer {
  display: flex;
  justify-content: space-around;
  background-color: #073618;
  color: #ffffff;
  padding: 30px 10px;
  width: 100%;
  box-sizing: border-box;
  .footer__contact {
    padding-top: 10px;
    p {
      font-size: 14px;
      margin: 5px 0;
    }
  }
  .footer__navs {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .footer--column {
    display: flex;
    flex-direction: column;
    margin: 0 20px;
    h2 {
      font-size: 16px;
      margin: 10px 0;
    }
    a,
    p {
      font-size: 14px;
      margin: 5px 0;
      text-decoration: none;
      color: #ffffff;
    }
  }
}

@media only screen and (max-width: 900px) {
  .footer {
    .footer--column {
      min-width: 150px;
      margin: 0;
    }
  }
}
