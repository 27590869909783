.features {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 100px;
  .features__title {
    text-align: center;
    color: #ffffff;
    font-family: MonumentExtended-Bold;
    font-size: 30px;
  }
  .features__row1,
  .features__row2 {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    > * {
      margin: 20px;
    }
  }
}

@media only screen and (max-width: 900px) {
  .features {
    .features__row1,
    .features__row2 {
      > * {
        margin: 10px 5px;
      }
    }
  }
}
