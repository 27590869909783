.headerMobile {
  display: none;
  a {
    text-decoration: none;
  }
}

@mixin contactWithLang {
  .header__contactWithLang {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 0.5;
    .header__lang {
      text-transform: none;
      font: normal 14px Helvetica;
      color: #ffffff;
      border-color: #00f96b;
      height: 40px;
      min-width: 130px;
    }
    .header__contact {
      height: 40px;
      background-color: #00f96b;
      // border-radius: 31px;
      margin: 0 0 0 20px;
      padding: 10px;
      color: #000000;
      font: normal normal bold 18px Helvetica;
      text-transform: none;
      white-space: nowrap;
      min-width: 130px;
    }
  }
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #000000;
  box-sizing: border-box;
  padding: 10px;

  a {
    text-decoration: none;
  }
  font: normal normal bold 22px Helvetica;
  .header__logo {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    img {
      height: 80px;
      width: 80px;
      cursor: pointer;
    }
  }
  .header__navbar {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    a,
    p {
      margin: auto 12px;
      text-decoration: none;
      color: #ffffff;
    }
    a {
      background: linear-gradient(to right, rgb(255, 255, 255), rgba(0, 249, 107, 1)),
        linear-gradient(to right, rgba(255, 0, 0, 1), rgba(255, 0, 180, 1), rgba(0, 100, 200, 1));
      background-size: 100% 3px, 0 3px;
      background-position: 100% 100%, 0 100%;
      background-repeat: no-repeat;
      transition: background-size 400ms;
      &:hover {
        background-size: 0 3px, 100% 3px;
      }
    }
  }

  @include contactWithLang;
}

.header--nobg {
  background-color: unset;
  transition-timing-function: ease-in;
  transition: 0.6s;
}
.header--fixed {
  position: fixed;
  top: 0;
  z-index: 100;
}

@media only screen and (max-width: 900px) {
  .header {
    display: none;
  }
  .headerMobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #000000;
    padding: 10px;
    border-bottom: 1px solid #fff;
    .header__logo {
      height: 55px;
    }
    @include contactWithLang;
    .header__contactWithLang {
      flex-grow: 0;
      flex-wrap: wrap;
      justify-content: flex-end;
      .header__lang {
        height: 35px;
        font-size: 13px;
        min-width: 120px;
        padding: 10px;
        margin: 5px 0;
      }
      .header__contact {
        height: 35px;
        font-size: 13px;
        min-width: 120px;
        padding: 10px;
        margin-left: 5px;
      }
    }
  }
}
