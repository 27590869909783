.paymentMethods {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  color: #ffffff;
  padding: 100px 0;
  .paymentMethods__text {
    max-width: 400px;
    margin-right: 20px;
    h2 {
      font-family: MonumentExtended-Regular;
      font-size: 35px;
    }
    ul {
      font-size: 17px;
    }
  }
  .paymentMethods__cards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .paymentMethods--column1,
    .paymentMethods--column2 {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }
    .paymentMethods--column2 {
      padding-top: 30px;
    }
  }
}

@media only screen and (max-width: 900px) {
  .paymentMethods {
    .paymentMethods__text {
      margin-right: 0;
      h2 {
        text-align: center;
      }
    }
  }
}
