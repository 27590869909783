.career__jobs {
  padding: 100px 0;
  .career__jobsSelection {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    > * {
      width: 250px;
      margin: 20px;
    }
  }
  .career__jobsList {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
  }
}
