.career {
  background-color: #f7f8fa;
  padding-bottom: 100px;
  .career__bg {
    img {
      width: 100%;
      min-height: 500px;
    }
  }
  .career__content {
    width: 75%;
    margin: 0 auto;
    .career__aboutUs {
      margin-bottom: 50px;
      h2 {
        color: #36ae37;
        text-align: center;
        font-size: 35px;
      }
      .career__aboutUs__text {
        margin-bottom: 100px;
        p {
          font-family: Montserrat-Regular;
          margin: auto;
          font-size: 20px;
          line-height: normal;
          margin-bottom: 20px;
        }
      }

      h1 {
        font-family: Montserrat-Bold;
        font-size: 35px;
        text-align: center;
        span {
          color: #36ae37;
        }
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .career {
    padding-top: 0;
    padding-bottom: 60px;
    .career__bg {
      img {
        min-height: auto;
      }
    }
    .career__content {
      width: auto;
      .career__aboutUs {
        .career__aboutUs__text {
          text-align: center;
          p {
            width: 80%;
          }
        }
      }
    }
  }
}
