.contact {
  min-height: 100vh;
  .contact__title {
    background-color: #073618;
    color: #ffffff;
    padding: 70px;
    text-align: center;
    h2 {
      font-family: Montserrat-Regular;
      font-size: 30px;
    }
  }
  .contact__form {
    padding: 50px;
    width: 70%;
    margin: auto;
    .contact__form__column {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 48%;
      > * {
        margin: 0;
        // height: 50px;
      }
    }
    .contact__form__sectionOne {
      display: flex;
      justify-content: space-between;
      .contact__form__column {
        > * {
          margin: 0;
          margin-bottom: 15px;
        }
      }
    }
    .contact__form__sectionTwo {
      .MuiFormLabel-root {
        font-family: Montserrat-Bold;
        color: #000000;
        font-size: 16px;
        margin: 20px 0;
      }
    }

    .contact__form__send {
      background-color: #36ae37;
      color: #ffffff;
      width: 200px;
      height: 50px;
      margin: 50px 0;
      .css-17xq7a1-MuiLoadingButton-loadingIndicator {
        left: 30px;
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .contact {
    .contact__form {
      padding: 50px 5px;
      width: 90%;
      .MuiInputLabel-root {
        font-size: 0.8rem;
      }
      .MuiFormControlLabel-label {
        font-size: 0.8rem;
      }
      .contact__form__sectionOne {
        flex-wrap: wrap;
        .contact__form__column {
          width: 100%;
        }
      }
      .contact__form__sectionTwo {
        .MuiFormLabel-root {
          font-size: 0.8rem;
        }
        .contact__form__column {
          width: auto !important;
          min-width: 160px;
        }
      }
    }
  }
}
