.apply {
  padding: 50px 0;
  .apply__titleContainer {
    background-color: #073618;
    padding: 30px;
    margin: 50px 0;
    text-align: center;
    font-family: Montserrat-Regular;
    color: #ffffff;
    h2 {
      font-size: 25px;
    }
    p {
      font-size: 15px;
    }
  }
  .apply__jobDetails {
    h2 {
      font-size: 25px;
      color: #36ae37;
    }
  }
  .apply__form {
    display: flex;
    align-items: center;
    flex-direction: column;

    .apply__send {
      background-color: #36ae37;
      color: #ffffff;
      width: 200px;
      height: 50px;
      margin: 50px 0;
      .css-17xq7a1-MuiLoadingButton-loadingIndicator {
        left: 30px;
      }
    }
    h2 {
      font-size: 25px;
      color: #36ae37;
      text-align: center;
    }
    > * {
      width: 70%;
      margin-top: 15px;
    }
  }
  .apply__linearProgress {
    background-color: #4dec50 !important;
    .css-5ir5xx-MuiLinearProgress-bar1 {
      background-color: #36ae37 !important;
    }
    .css-1r8wrcl-MuiLinearProgress-bar2 {
      background-color: #36ae37 !important;
    }
  }
}

@media only screen and (max-width: 900px) {
  .apply {
    .apply__form {
      padding: 20px;
      > * {
        width: 100%;
        margin-top: 15px;
      }
    }
  }
}
