.home {
  background-color: #000000;
  .home__background {
    position: relative;
    min-height: 700px;
    .home__background__text {
      text-align: center;
      position: absolute;
      width: 50%;
      top: 30%;
      transform: translate(50%, 0);
      color: #ffffff;
      > *:nth-child(1) {
        font: normal normal 800 40px MonumentExtended-Regular;
      }
      > *:nth-child(2) {
        font: normal normal normal 20px AvenirNext;
      }
    }
    .home__backgroundVideo {
      width: 100%;
    }
  }
  .home__hiring,
  .home__contactUs {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #073618;
    color: #ffffff;
    text-align: center;
    padding: 70px 0;
    h2 {
      font-family: MonumentExtended-Regular;
    }
    p {
      margin: 30px 0;
    }
    button {
      background-color: #439a63;
      text-transform: none;
    }
  }

  .home__safety {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    color: #ffffff;
    padding: 100px;
    .home__safety__textContainer {
      margin-left: 20px;
      max-width: 450px;
      h2 {
        font-family: MonumentExtended-Regular;
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .home {
    .home__background {
      .home__background__text {
        width: auto;
        right: 50%;
        top: 10%;
        // transform: translate(0, 50%);
      }
      .home__backgroundVideo {
        display: none;
      }
    }
  }
}
