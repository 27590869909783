.mobilePhone {
  position: relative;
  .mobilePhone__flagContainer {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 7px;
    .mobilePhone__arow {
      border-style: solid;
      border-width: 6px 4px;
      border-color: #555 transparent transparent;
      margin-left: 2px;
      margin-top: 10px;
    }
    .arrow--up {
      border-color: transparent transparent #555;
      margin-top: 0;
      margin-bottom: 2px;
    }
  }
  .mobilePhone__countrySelect {
    z-index: 500;
    position: absolute;
    top: 50px;
    width: 100%;
    background-color: #fff;
    padding: 10px;
    box-sizing: border-box;
    // border: solid 1px rgba(0, 0, 0, 0.23);
    box-shadow: 1px 2px 18px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
  }
  
}
